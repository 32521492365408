import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/store/app.reducer';
import { BaseFacade } from '../base.facade';
import { getRoles, resetSlice, updateRoleConfiguration } from './role.actions';
import { roleSelectors } from './role.selectors';
import { RoleConfigurationDTO } from '../../domain/dto/role-configuration.dto';
import { map, withLatestFrom } from 'rxjs';
import { ClientFacade } from '../client/client.facade';

@Injectable({
  providedIn: 'root',
})
export class RoleFacade extends BaseFacade {
  loadingRoles$ = this._store.pipe(select(roleSelectors.loadingRoles));
  roles$ = this._store.pipe(select(roleSelectors.getRoles));
  getRoleById$ = (id: string) =>
    this._store.pipe(
      select(roleSelectors.getRoleById),
      map((fn) => fn(id))
    );
  getRolesByClientId$ = (clientId: string) =>
    this._store.pipe(
      select(roleSelectors.getRolesByClientId),
      withLatestFrom(this.clientFacade.getClientById$(clientId)),
      map(([fn, client]) => fn(clientId, client?.config?.useGeneralRoles))
    );

  constructor(
    private readonly clientFacade: ClientFacade,
    _store: Store<AppState>
  ) {
    super(_store);
  }

  getRoles$() {
    return this.dispatchAction$(getRoles);
  }

  updateRoleConfiguration$(roleConfiguration: RoleConfigurationDTO[]) {
    return this.dispatchAction$(updateRoleConfiguration, { roleConfiguration });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
