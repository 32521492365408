import { Component, OnInit } from '@angular/core';
import { CommunitiesOfInterestConfigComponent } from './communities-of-interest-config/communities-of-interest-config.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { Subject, combineLatest, forkJoin, take, takeUntil } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
import {
  AbstractWebPartComponent,
  VIEW,
  AbstractWebPartConfigComponent,
  WebPartConfig,
  WebpartViewBox,
  hexToRgb,
  DataSourceService,
} from 'processdelight-angular-components';
import { isB2B } from 'src/app/core/extensions/b2x.extensions';
import { InterestGroupFacade } from 'src/app/core/store/interestGroup/interestGroup.facade';
import { InterestGroup } from 'src/app/core/domain/models/interestGroup';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CoiFilterPopupComponent } from './coi-filter-popup/coi-filter-popup.component';
import { TranslationService } from 'src/app/core/services/translation.service';

interface Groups {
  id: string;
  title: string;
  color: string;
  description?: string;
  icon?: string;
  isSelected: boolean;
  children?: Groups[];
}

@Component({
  standalone: true,
  selector: 'app-communities-of-interest',
  templateUrl: './communities-of-interest.component.html',
  styleUrls: ['./communities-of-interest.component.scss'],
  imports: [
    CommonModule,
    MatCardModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
  ],
})
export class CommunitiesOfInterestComponent
  extends AbstractWebPartComponent
  implements OnInit
{
  static override TYPE = 'CommunitiesOfInterest';
  static override FEATURES = [VIEW];
  static override CONFIG_COMPONENT: new () => AbstractWebPartConfigComponent =
    CommunitiesOfInterestConfigComponent;

  isB2B = false;
  destroy$ = new Subject<void>();
  interestGroups: InterestGroup[] = [];
  interestGroupsForUser: InterestGroup[] = [];
  forcedGroup?: InterestGroup;
  showAllGroups = true;
  treeControl = new NestedTreeControl<Groups>((node) => node.children ?? []);
  dataSource = new MatTreeNestedDataSource<Groups>();

  //height 1 en niet oneGroup => filter knop met chips

  hasChild = (_: number, node: Groups) =>
    !!node.children && node.children.length > 0;

  override set configurations(value: WebPartConfig[]) {
    this._configurations = value;
  }

  override get configurations(): WebPartConfig[] {
    return this._configurations ?? [];
  }

  override set webpartViewbox(value: WebpartViewBox | undefined) {
    this._webpartViewbox = value;
  }

  get filteredGroups() {
    return [...this.dataSource.data.filter((g) => g.isSelected)].sort((a, b) =>
      a.title.localeCompare(b.title)
    );
  }

  getTranslation$(key: string) {
    return this.translationService.getTranslation$(key);
  }

  constructor(
    private translationService: TranslationService,
    private interestGroupFacade: InterestGroupFacade,
    private readonly sanitizer: DomSanitizer,
    private filterDialog: MatDialog,
    private dataService: DataSourceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataService.updateData('filteredInterestGroups', []);
    combineLatest([
      this.interestGroupFacade.interestGroups$,
      this.interestGroupFacade.interestGroupsForUser$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([interestGroups, interestGroupsForUser]) => {
        this.dataSource.data = [];
        if (interestGroups) {
          this.interestGroups = interestGroups.map(
            (g) => new InterestGroup({ ...g })
          );
        }
        if (interestGroupsForUser) {
          this.interestGroupsForUser = interestGroupsForUser.map(
            (g) => new InterestGroup({ ...g })
          );
        }
        const configuration = this.configurations?.find(
          (c) => c.name === 'communityGroupIds'
        );
        if (configuration) {
          const parsedConfiguration = this.getParsedConfiguration(
            configuration.configuration
          );
          this.showAllGroups = parsedConfiguration.showAllGroups;
          this.forcedGroup = this.interestGroups.find(
            (g) => g.id === parsedConfiguration.oneGroup
          );
        }
        this.dataSource.data = this.buildTree(
          this.forcedGroup
            ? [this.forcedGroup]
            : this.showAllGroups
            ? interestGroups
            : interestGroupsForUser
        );
      });
  }

  buildTree(interestGroups: InterestGroup[]): Groups[] {
    if (!interestGroups) {
      return [];
    }
    return interestGroups.map((g) => ({
      id: g.id,
      title: g.title,
      color: g.color,
      description: g.description,
      icon: g.icon,
      isSelected: g.isSelected,
      children: this.getConvertedNodes(g),
    }));
  }

  getConvertedNodes(interestGroup: InterestGroup): Groups[] {
    if (interestGroup.subGroups && interestGroup.subGroups.length === 0) {
      return [];
    }
    const r = interestGroup.subGroups!.map((group) => {
      const subGroup = this.interestGroups?.find(
        (g) => g.id === group.subGroupId
      );
      return {
        id: subGroup!.id,
        title: subGroup!.title,
        color: subGroup!.color,
        description: subGroup!.description,
        icon: subGroup!.icon,
        isSelected: subGroup!.isSelected,
        children: this.getConvertedNodes(subGroup!),
      };
    });
    return r;
  }

  checkBoxChange(event: MatCheckboxChange, node: Groups) {
    let groupsToCheck = this.showAllGroups
      ? this.interestGroups
      : this.interestGroupsForUser;
    let allGroupsToJoin = [node.id];
    if (event.checked) {
      let newSubGroups = allGroupsToJoin;
      do {
        newSubGroups = groupsToCheck
          .filter(
            (g) =>
              !allGroupsToJoin.includes(g.id) &&
              g.subGroups?.some((s) => newSubGroups.includes(s.subGroupId))
          )
          .map((g) => g.id);
        allGroupsToJoin = allGroupsToJoin.concat(newSubGroups);
      } while (newSubGroups.length != 0);
    }
    groupsToCheck = groupsToCheck.map((g) => {
      if (allGroupsToJoin.includes(g.id)) {
        g.isSelected = event.checked;
      }
      return g;
    });
    this.dataSource.data = this.buildTree(groupsToCheck);
    this.dataService.updateData(
      'filteredInterestGroups',
      this.filteredGroups.map((g) => g.id)
    );
    console.log(this.dataService.getData('filteredInterestGroups'));
  }

  getParsedConfiguration(value: string) {
    return {
      showAllGroups: value ? JSON.parse(value).showAllGroups : true,
      oneGroup: value ? JSON.parse(value).oneGroup : '',
    };
  }

  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  getTextColor(color: string) {
    const rgb = hexToRgb(color);
    if (rgb) {
      return rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 <= 186
        ? '#fff'
        : '#000';
    } else {
      return '#fff';
    }
  }

  openFilterDialog() {
    this.filterDialog
      .open(CoiFilterPopupComponent, {
        width: '50%',
        height: '70%',
        data: {
          interestGroups: this.showAllGroups
            ? this.interestGroups
            : this.interestGroupsForUser,
          dataSource: this.dataSource.data,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.dataSource.data = result;
          this.dataService.updateData(
            'filteredInterestGroups',
            this.filteredGroups.map((g) => g.id)
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.dataService.updateData('filteredInterestGroups', undefined);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
