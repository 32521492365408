import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import { DataService } from 'src/app/webparts/dms-component/services/data.service';
import { TranslationService } from '../../services/translation.service';
import {
  getMetadataParams,
  getMetadataParamsResolved,
} from './metadata.actions';

@Injectable({ providedIn: 'root' })
export class MetadataEffects {
  constructor(
    private actions$: Actions,
    private api: DataService,
    private translations: TranslationService
  ) {}

  getTranslation(label: string): string {
    return this.translations.getTranslation(label);
  }

  getMetadataParams = createEffect(() =>
    this.actions$.pipe(
      ofType(getMetadataParams),
      switchMap(({ callback, error }) =>
        this.api.getMetadataParams().pipe(
          tap((metadataParams) =>
            callback ? callback(metadataParams) : undefined
          ),
          switchMap((metadataParams) =>
            of(getMetadataParamsResolved({ metadataParams }))
          ),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );
}
