import { createAction, props } from '@ngrx/store';
import { Role } from '../../domain/models/role.model';
import { ActionBase } from '../action-base.interface';
import { RoleConfigurationDTO } from '../../domain/dto/role-configuration.dto';
import { ClientWithConfig } from '../../domain/models/client-with-config.model';

export const getRoles = createAction(
  '[Role] Get roles',
  props<ActionBase<Role[]>>()
);
export const getRolesResolved = createAction(
  '[Role] Get roles resolved',
  props<{ roles: Role[] }>()
);

export const updateRoleConfiguration = createAction(
  '[Role] Update role configuration',
  props<
    { roleConfiguration: RoleConfigurationDTO[] } & ActionBase<{
      roles: Role[];
      clientConfigs: ClientWithConfig[];
    }>
  >()
);

export const resetSlice = createAction('[Role] Reset slice');
