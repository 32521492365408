import { createAction, props } from '@ngrx/store';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import { ActionBase } from '../../action-base.interface';
import { PortalPostLike } from 'src/app/core/domain/models/portal-post-like.model';
import { PortalPostComment } from 'src/app/core/domain/models/portal-post-comment.model';

export const getPosts = createAction(
  '[Feed] Get posts',
  props<
    {
      portalUserId: string;
      orderByDir: 'asc' | 'desc';
      filter: string | undefined;
    } & ActionBase<PortalPost[]>
  >()
);
export const getPostsResolved = createAction(
  '[Feed] Get posts resolved',
  props<{
    feed: PortalPost[];
  }>()
);

export const createPost = createAction(
  '[Feed] Create post',
  props<{ post: PortalPost } & ActionBase<PortalPost>>()
);
export const createPostResolved = createAction(
  '[Feed] Create post resolved',
  props<{
    post: PortalPost;
  }>()
);

export const likePost = createAction(
  '[Feed] Like post',
  props<{ like: PortalPostLike } & ActionBase<PortalPostLike>>()
);
export const likePostResolved = createAction(
  '[Feed] Like post resolved',
  props<{
    like: PortalPostLike;
  }>()
);

export const removeLike = createAction(
  '[Feed] Remove post',
  props<{ like: PortalPostLike } & ActionBase<string>>()
);
export const removeLikeResolved = createAction(
  '[Feed] Remove post resolved',
  props<{
    likeId: string;
    postId?: string;
    commentId?: string;
  }>()
);

export const createComment = createAction(
  '[Feed] Create comment',
  props<{ comment: PortalPostComment } & ActionBase<PortalPostComment>>()
);
export const createCommentResolved = createAction(
  '[Feed] Create comment resolved',
  props<{
    comment: PortalPostComment;
  }>()
);

export const deleteComment = createAction(
  '[Feed] Delete comment',
  props<{ comment: PortalPostComment } & ActionBase<string>>()
);
export const deleteCommentResolved = createAction(
  '[Feed] Delete comment resolved',
  props<{
    postId: string;
    commentId: string;
  }>()
);

export const resetSlice = createAction('[Feed] Reset slice');
