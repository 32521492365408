<div #webPartContainer class="h-100 w-100">
  <mat-card class="h-100 d-flex flex-column list-background p-0">
    <mat-card-header
      class="d-flex flex-row align-items-center w-100"
      [style.display]="shouldDisplayCardHeader ? 'flex' : 'none !important'"
    >
      <div class="align-self-start me-2">
        <mat-card-title>DMS</mat-card-title>
      </div>
      <div
        [style.display]="shouldDisplayFilter ? 'flex' : 'none !important'"
        class="d-flex flex-column w-100 p-1 filter-container"
        *ngIf="metadataParameters$ | async as params"
      >
        <div
          class="d-flex flex-row justify-content-between align-items-center w-100"
        >
          <div class="d-flex flex-row align-items-center w-90">
            <button mat-icon-button (click)="toggleFiltering()">
              <mat-icon class="material-icons-outlined">
                filter_alt{{ filtering ? "_off" : "" }}
              </mat-icon>
            </button>
            <ng-container *ngIf="filtering">
              <mat-form-field
                appearance="outline"
                class="ms-2 me-2 no-subscript-wrapper filter-dropdown"
              >
                <mat-label>{{ getTranslation$("filter") | async }}</mat-label>
                <mat-select
                  (openedChange)="$event ? focusInput(searchInput) : undefined"
                  panelClass="p-0"
                  [formControl]="filterParamControl"
                >
                  <div class="d-flex flex-column h-100">
                    <mat-form-field class="no-subscript-wrapper w-100">
                      <mat-label>{{
                        getTranslation$("search") | async
                      }}</mat-label>
                      <input matInput type="text" #searchInput />
                    </mat-form-field>
                    <div class="overflow-auto scrollbar">
                      <mat-option
                        *ngFor="
                          let param of getFilteredParams(params);
                          trackBy: paramTrackBy
                        "
                        [value]="param"
                        [hidden]="
                          param.title
                            .toLowerCase()
                            .indexOf(searchInput.value) === -1
                        "
                      >
                        {{ param.title }}
                      </mat-option>
                    </div>
                  </div>
                </mat-select>
              </mat-form-field>
              <ng-container *ngIf="filterParamControl.value">
                <app-adaptable-input
                  class="no-subscript-wrapper"
                  [param]="filterParamControl.value"
                  [params]="params"
                  [library]="library"
                  [filter]="true"
                  [formControl]="filterValueControl"
                  #filterValueElement
                ></app-adaptable-input>
                <div class="d-flex align-items-center">
                  <button
                    mat-mini-fab
                    color="primary"
                    class="ms-2"
                    (click)="addFilter()"
                    [disabled]="
                      filterValueControl.invalid || filterValueControl.pristine
                    "
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <mat-chip-listbox>
          <mat-chip
            *ngFor="let filter of filterValueArray; trackBy: filterTrackBy"
            (click)="selectFilter(params, filter.paramId, filter.value)"
          >
            {{ getParamById(params, filter.paramId)?.title }}:
            {{ getParamValue(params, filter.paramId, filter.value) }}
            <button matChipRemove (click)="removeFilter(filter.paramId)">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <div class="flex-grow-1"></div>
      <mat-spinner *ngIf="loading" diameter="40" class="me-2"></mat-spinner>
    </mat-card-header>
    <mat-card-content class="h-100 w-100 d-flex flex-column min-height-0">
      <div class="d-flex flex-column h-100 min-height-0" *ngIf="initialized">
        <div class="flex-grow-1 pt-2 min-height-0" #dashboardWrapper>
          <processdelight-dashboard
            [style.display]="shouldDisplayTable ? '' : 'none !important'"
            class="hide-paginator h-100 d-flex flex-column"
            [data]="pagedItems"
            [maxSizeItems]="totalRecordCount"
            [pageSize]="pageSize"
            [possibleColumns]="possibleColumns"
            [selectedColumns]="selectedColumns"
            [showExpandedDetails]="false"
            [sortColumn]="orderBy"
            [sortDirection]="orderByDirection ?? ''"
            (sortChange)="sortChange($event)"
            (rowExpanded)="!appLibrary ? openPreview($event) : undefined"
            (columnChange)="columnChange($event)"
            [emptyTableText]="noItems"
            [itemTrackByFn]="itemTrackByFn"
            [selectedItems]="selectedItems"
            [columnBreakpointObserver]="false"
            [highlightOnHover]="true"
            [horizontalScroll]="true"
            [columnsResizable]="true"
            (selectionChanged)="selectedItems = $event"
            (columnWidthChanged)="columnWidthChange($event)"
            [multiSelect]="false"
            [multiSelectActions]="multiSelectActions"
            [highlightSelected]="true"
            [arrowKeySelectionChange]="false"
            [customContextMenu]="true"
            [contextMenuActionsFn]="itemContextMenuFn"
          ></processdelight-dashboard>
        </div>
        <ng-template #noItems>
          <div
            class="w-100 h-100 d-flex justify-content-center align-items-center"
          >
            <h1 *ngIf="!loading">{{ getTranslation$("noResults") | async }}</h1>
          </div>
        </ng-template>
        <div
          class="text-center"
          [style.display]="shouldDisplayButton ? '' : 'none !important'"
        >
          <button mat-button (click)="openListDialog()">
            {{ getTranslation$("showMore") | async }}
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
