<div class="d-flex flex-row justify-content-between align-items-center p-2">
  <div class="d-flex flex-row gap-5 align-items-center">
    <h1>Feed</h1>
    <button mat-button [matMenuTriggerFor]="menu">
      {{ orderBy === "desc" ? "Recent" : "Oldest" }}
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="orderBy = 'desc'; searchPosts()">
        Recent
      </button>
      <button mat-menu-item (click)="orderBy = 'asc'; searchPosts()">
        Oldest
      </button>
    </mat-menu>
  </div>
  <div class="d-flex flex-row gap-5 align-items-center">
    <mat-form-field class="no-subscript-wrapper searchbar" appearance="fill">
      <mat-label>Search</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="searchTerm"
        (ngModelChange)="searchPosts()"
      />
      <button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        *ngIf="searchTerm"
        (click)="searchTerm = ''; searchPosts()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button
      *ngIf="canCreatePosts"
      class="createpost"
      mat-stroked-button
      (click)="createNewPost()"
    >
      Create Post
    </button>
  </div>
</div>

<div class="d-flex flex-column gap-3 ms-3 me-2 pe-1 mb-3 scrollbar feed">
  <div
    *ngFor="let post of feed; trackBy: postTrackBy"
    class="d-flex flex-column gap-3"
  >
    <app-feed-post
      [post]="post"
      [groupUserId]="groupUserId"
      [portalUserId]="portalUserId"
    >
    </app-feed-post>
  </div>
  <div
    class="d-flex flex-column align-items-center justify-content-between h-100"
    *ngIf="loading"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
