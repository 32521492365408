import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom,
  inject,
  Injector,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideStore, StoreModule } from '@ngrx/store';
import {
  provideStoreDevtools,
  StoreDevtoolsModule,
} from '@ngrx/store-devtools';
import { AppComponent } from './app/app.component';
import { microsoftAuthenticationInterceptor } from './app/msal/injectables/microsoft-authentication.interceptor';
import { MSALModule } from './app/msal/msal.module';
import { ApplicationService } from './app/core/services/application.service';
import { environment } from './environments/environment';
import {
  DatacomponentService,
  ISHTAR365_APP_IDENTIFIER,
  ISHTAR365_CLIENTID,
  ISHTAR365_FUNCTIONS_SCOPE,
  ISHTAR365_FUNCTIONS_URL,
  ISHTAR365_INITIAL_ROUTE,
  ISHTAR365_INSTANCE,
  ISHTAR365_URL,
  RelationRetrieverService,
} from 'processdelight-angular-components';
import { routes } from './app/app.routes';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PortalService } from './app/core/services/portal.service';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: ISHTAR365_CLIENTID, useValue: environment.clientId },
    { provide: ISHTAR365_INSTANCE, useValue: environment.instance },
    { provide: ISHTAR365_URL, useValue: environment.ishtar365 },
    { provide: ISHTAR365_APP_IDENTIFIER, useFactory: () => location.origin },
    { provide: ISHTAR365_FUNCTIONS_URL, useValue: environment.ishtarFunctions },
    {
      provide: ISHTAR365_FUNCTIONS_SCOPE,
      useValue: environment.ishtarFunctionsScope,
    },
    { provide: ISHTAR365_INITIAL_ROUTE, useValue: ['page'] },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const service = inject(ApplicationService);
        return () => service.boot();
      },
    },
    {
      provide: RelationRetrieverService,
      useClass: PortalService,
    },
    {
      provide: DatacomponentService,
      useClass: PortalService,
    },
    provideRouter(routes),
    provideStore({}),
    provideEffects([]),
    provideStoreDevtools({ logOnly: false }),
    provideHttpClient(withInterceptors([microsoftAuthenticationInterceptor])),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({ logOnly: environment.production }),
      MSALModule,
      MatSnackBarModule
    ),
    provideAnimations(),
  ],
});
