import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { GroupUser } from 'processdelight-angular-components';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  first,
  forkJoin,
  map,
  switchMap,
  tap,
  timeout,
} from 'rxjs';
import { isB2C } from 'src/app/core/extensions/b2x.extensions';
import { camelcaseKeys } from 'src/app/core/extensions/object.extensions';
import { ClientFacade } from 'src/app/core/store/client/client.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import * as uuid from 'uuid';
import { LibraryItem } from '../domain/models/item.model';
import { Library } from '../domain/models/library.model';
import { MetadataParam } from '../domain/models/metadata-param.model';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import { PortalPostLike } from 'src/app/core/domain/models/portal-post-like.model';
import { PortalPostComment } from 'src/app/core/domain/models/portal-post-comment.model';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  apiBase = `${location.origin}/web`;
  static tenantId = '93e3ed2e-e22f-4a14-801c-53d1c4279e2a';

  constructor(
    private httpClient: HttpClient,
    private userFacade: UserFacade,
    private clientFacade: ClientFacade
  ) {}

  users$ = new BehaviorSubject<GroupUser[]>([]);
  groups$ = new BehaviorSubject<GroupUser[]>([]);

  private b2x() {
    return isB2C() ? 'b2c' : 'b2b';
  }

  // apiService
  // getAppInfo(app: string) {
  //   return this.httpClient
  //     .get<AppInfo>(`${DataService.apiBase}/organization/app/${app}`)
  //     .pipe(map((info) => new AppInfo(camelcaseKeys(info))));
  // }

  getLibraries() {
    return this.httpClient
      .get<Library[]>(`${this.apiBase}/${this.b2x()}/dms/libraries`)
      .pipe(map((m) => m.map((p) => new Library(camelcaseKeys(p)))));
  }

  getLibrary(id: string) {
    return this.httpClient
      .get<Library>(`${this.apiBase}/${this.b2x()}/dms/libraries/${id}`)
      .pipe(map((l) => new Library(camelcaseKeys(l))));
  }

  getSharedItems(
    orderBy: string | undefined,
    orderByDirection: 'asc' | 'desc' | undefined,
    filters: { [key: string]: string },
    pageSize: number,
    page: number
  ) {
    const parseFilters = (filters: {
      [key: string]: string | { start: Date; end: Date } | GroupUser;
    }) => {
      let result = '';
      Object.keys(filters).forEach((key) => {
        if (typeof filters[key] == 'string') {
          if (uuid.validate(filters[key] as string))
            result += `&filter=${key} eq ${filters[key]}`;
          else result += `&filter=${key} in ${filters[key]}`;
        } else if (typeof filters[key] == 'object') {
          if (filters[key] instanceof GroupUser) {
            const val = filters[key] as GroupUser;
            result += `&filter=${key} eq ${val.xId}`;
          } else {
            const val = filters[key] as { start: Date; end: Date };
            if (val.start)
              result += `&filter=${key} ge ${DateTime.fromJSDate(
                val.start
              ).toFormat('dd/MM/yyyy HH:mm')}`;
            if (val.end)
              result += `&filter=${key} le ${DateTime.fromJSDate(
                val.end
              ).toFormat('dd/MM/yyyy HH:mm')}`;
          }
        } else if (typeof filters[key] == 'number') {
          result += `&filter=${key} eq ${filters[key]}`;
        }
      });
      return result;
    };
    return this.httpClient
      .get<{ result: LibraryItem[]; totalRecordCount: number }>(
        `${
          this.apiBase
        }/${this.b2x()}/dms/documents/shared?pageSize=${pageSize}&page=${page}${
          orderBy ? '&orderBy=' + orderBy : ''
        }${
          orderByDirection ? '&orderByDirection=' + orderByDirection : ''
        }${parseFilters(filters)}`
      )
      .pipe(
        map(({ result, totalRecordCount }) => ({
          result: result.map((p) => new LibraryItem(camelcaseKeys(p))),
          totalRecordCount,
        }))
      );
  }

  getLibraryItems(
    orderBy: string | undefined,
    orderByDirection: 'asc' | 'desc' | undefined,
    filters: { [key: string]: string },
    pageSize: number,
    page: number,
    filterActive: boolean,
    libraryId: string | undefined = undefined,
    filteredInterestGroupIds: string[] | undefined = undefined
  ) {
    const parseFilters = (filters: {
      [key: string]: string | { start: Date; end: Date } | GroupUser;
    }) => {
      const result = Object.keys(filters)
        .map((key) => {
          if (typeof filters[key] == 'string') {
            if (uuid.validate(filters[key] as string))
              return `(${key} eq ${filters[key]})`;
            else
              return `(${key} in '${encodeURIComponent(
                filters[key] as string
              )}')`;
          } else if (typeof filters[key] == 'object') {
            if (filters[key] instanceof GroupUser) {
              const val = filters[key] as GroupUser;
              return `(${key} eq ${val.id})`;
            } else {
              const val = filters[key] as { start: Date; end: Date };
              let dateFilter = '';
              if (val.start)
                dateFilter += `(${key} ge ${DateTime.fromJSDate(
                  val.start
                ).toFormat('dd/MM/yyyy HH:mm')})`;
              if (val.start && val.end) dateFilter += ',';
              if (val.end)
                dateFilter += `(${key} le ${DateTime.fromJSDate(
                  val.end
                ).toFormat('dd/MM/yyyy HH:mm')})`;
              return dateFilter;
            }
          } else if (typeof filters[key] == 'number') {
            return `(${key} eq ${filters[key]})`;
          }
          return undefined;
        })
        .filter((f) => !!f)
        .join(',');
      return result;
    };
    if (pageSize <= 0) pageSize = 100;
    const parseQueryString = (items: {
      [key: string]: string | number | boolean | undefined;
    }) => {
      return Object.keys(items)
        .filter((key) => !!items[key])
        .map((key) => `${key}=${items[key]}`)
        .join('&');
    };
    return (
      isB2C()
        ? forkJoin([
            this.clientFacade.currentClient$.pipe(first()),
            this.userFacade.externalUserInfo$.pipe(first()),
          ]).pipe(
            first(),
            switchMap(([client, user]) =>
              this.httpClient.post<{
                result: LibraryItem[];
                totalRecordCount: number;
              }>(
                `${this.apiBase}/${this.b2x()}/dms/items?${parseQueryString({
                  pageSize,
                  page,
                  orderBy,
                  orderByDirection,
                  filter: parseFilters(filters),
                  filterActive,
                  libraryId,
                  clientId: client?.id,
                  userId: user?.user.id,
                })}`,
                filteredInterestGroupIds ?? []
              )
            )
          )
        : this.httpClient.get<{
            result: LibraryItem[];
            totalRecordCount: number;
          }>(
            `${this.apiBase}/${this.b2x()}/dms/items?${parseQueryString({
              pageSize,
              page,
              orderBy,
              orderByDirection,
              filter: parseFilters(filters),
              libraryId,
            })}`
          )
    ).pipe(
      map(({ result, totalRecordCount }) => ({
        result: result.map((p) => new LibraryItem(camelcaseKeys(p))),
        totalRecordCount,
      }))
    );
  }

  getMetadataParams() {
    return this.httpClient
      .get<MetadataParam[]>(`${this.apiBase}/${this.b2x()}/metadata/parameters`)
      .pipe(map((params) => params.map((p) => new MetadataParam(p))));
  }

  getLibraryFilePreview(sharepointId: string) {
    return this.httpClient.get<string>(
      `${this.apiBase}/${this.b2x()}/dms/${sharepointId}/preview`,
      { responseType: 'text' as 'json' }
    );
  }

  getLibraryFileLocation(sharepointId: string) {
    return this.httpClient.get<string>(
      `${this.apiBase}/${this.b2x()}/dms/${sharepointId}/location`,
      { responseType: 'text' as 'json' }
    );
  }

  getBlob(url: string, timeoutAfter = 10000) {
    let pipe;
    if (timeoutAfter > 0)
      pipe = timeout({
        first: timeoutAfter,
      });
    else pipe = tap(() => undefined);
    return this.httpClient
      .get<Blob>(url, { responseType: 'blob' as 'json' })
      .pipe(pipe) as Observable<Blob>;
  }

  getLibraryFileDesktopAppUrl(sharepointId: string, edit = false) {
    return this.httpClient.get<string>(
      `${
        this.apiBase
      }/${this.b2x()}/dms/documents/${sharepointId}/desktopApp?edit=${edit}`,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  uploadImage(
    library: string,
    fileName: string,
    extension: string,
    base64: string
  ) {
    return this.httpClient.post<string>(
      `${this.apiBase}/${this.b2x()}/feed/file`,
      {
        relativeUrl: library,
        fileName: fileName,
        base64,
      },
      { responseType: 'text' as 'json' }
    );
  }

  blobToBase64(blob: Blob) {
    const subject = new Subject<string>();
    const fr = new FileReader();
    fr.onloadend = () => {
      subject.next(fr.result as string);
      subject.complete();
    };
    fr.readAsDataURL(blob);
    return subject.asObservable();
  }

  deleteImage(relativeUrl: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/${this.b2x()}/feed/file?relativeUrl=${relativeUrl}`
    );
  }

  getPosts(
    portalUserId: string,
    orderByDirection: 'asc' | 'desc',
    filter?: string
  ) {
    return this.httpClient
      .get<PortalPost[]>(
        `${
          this.apiBase
        }/${this.b2x()}/feed/post?portalUserId=${portalUserId}&orderByDir=${orderByDirection}&filter=${filter}`
      )
      .pipe(map((posts) => posts.map((p) => new PortalPost(p))));
  }

  createPost(post: PortalPost) {
    return this.httpClient
      .post<PortalPost>(`${this.apiBase}/${this.b2x()}/feed/post`, post)
      .pipe(map((p) => new PortalPost(p)));
  }

  createLike(like: PortalPostLike) {
    return this.httpClient
      .post<PortalPostLike>(`${this.apiBase}/${this.b2x()}/feed/like`, like)
      .pipe(map((p) => new PortalPostLike(p)));
  }
  removeLike(id: string) {
    return this.httpClient.delete<string>(
      `${this.apiBase}/${this.b2x()}/feed/like/${id}`,
      { responseType: 'text' as 'json' }
    );
  }

  createComment(comment: PortalPostComment) {
    return this.httpClient
      .post<PortalPostComment>(
        `${this.apiBase}/${this.b2x()}/feed/comment`,
        comment
      )
      .pipe(map((p) => new PortalPostComment(p)));
  }
  removeComment(id: string) {
    return this.httpClient.delete<string>(
      `${this.apiBase}/${this.b2x()}/feed/comment/${id}`,
      { responseType: 'text' as 'json' }
    );
  }
}
