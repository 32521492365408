import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/core/store/app.reducer';

const roleState = (state: AppState) => state.coreFeature.role;
const loadingRoles = createSelector(roleState, (state) => state.loadingRoles);
const getRoles = createSelector(roleState, (state) => state.roles);
const getRoleById = createSelector(
  getRoles,
  (roles) => (id: string) => roles.find((role) => role.id === id)
);
const getRolesByClientId = createSelector(
  getRoles,
  (roles) =>
    (clientId: string, useGeneralRoles = true) =>
      roles.filter(
        (role) =>
          (useGeneralRoles && !role.client) ||
          role.client?.id === clientId
      )
);
export const roleSelectors = {
  loadingRoles,
  getRoles,
  getRoleById,
  getRolesByClientId,
};
