import { DialogModule, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { QuillConfigModule, QuillModule } from 'ngx-quill';
import {
  ContextMenuAction,
  DropZoneComponent,
  LoaderService,
  SelectComponent,
  TopLayoutComponent,
} from 'processdelight-angular-components';
import {
  orgLogo$,
  userName$,
  navColor$,
  navContrast$,
} from 'src/app/app.observables';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import { PortalService } from 'src/app/core/services/portal.service';
import { GroupFacade } from 'src/app/core/store/group/group.facade';
import { InterestGroupFacade } from 'src/app/core/store/interestGroup/interestGroup.facade';
import { DataService } from '../../dms-component/services/data.service';
import { FeedFacade } from 'src/app/core/store/custom/feed/feed.facade';
import {
  BehaviorSubject,
  filter,
  first,
  Subject,
  switchMap,
  takeUntil,
  tap,
  zip,
} from 'rxjs';
import { InterestGroup } from 'src/app/core/domain/models/interestGroup';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from 'src/app/core/core.module';
import { UserFacade } from 'src/app/core/store/user/user.facade';

@Component({
  selector: 'app-post-popup',
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    QuillModule,
    TopLayoutComponent,
    DropZoneComponent,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    SelectComponent,
    MatDialogModule,
  ],
  templateUrl: './post-popup.component.html',
  styleUrls: ['./post-popup.component.scss'],
})
export class PostPopupComponent implements OnInit, OnDestroy {
  constructor(
    private dialogRef: DialogRef,
    private igFac: InterestGroupFacade,
    private feedFac: FeedFacade,
    private dataService: DataService,
    private userFacade: UserFacade,
    private loader: LoaderService
  ) {}

  formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    // 'image'
    // 'video'
  ];
  modules = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ];

  orgLogo$ = orgLogo$;
  userName$ = userName$;
  navColor$ = navColor$;
  navContrast$ = navContrast$;

  groups$ = new BehaviorSubject<InterestGroup[]>([]);

  postFormField!: FormGroup;

  savedFile: File | null = null;
  portalUserId: string | undefined;
  groupUserId: string | undefined;

  buttonActions = [
    new ContextMenuAction({
      icon: 'save',
      label: 'Save',
      action: () => this.submitPost(),
    }),
  ];
  iconActions: ContextMenuAction<unknown>[] = [
    new ContextMenuAction({
      icon: 'close',
      label: 'Close',
      action: () => this.close(),
    }),
  ];

  get selectedImgUrl() {
    return this.postFormField.controls.image.value;
  }

  get groupsRestrictionEnabled() {
    return this.postFormField.controls.groupsRestrictionEnabled.value;
  }

  get selectedGroupIds(): string[] {
    return this.postFormField.controls.groups.value ?? [];
  }

  ngOnInit(): void {
    this.igFac.interestGroups$.pipe(takeUntil(this.destroy$)).subscribe((g) => {
      this.groups$.next(g);
    });

    zip([this.userFacade.externalUserInfo$, this.userFacade.userLicenseInfo$])
      .pipe(
        filter((e) => !!e),
        first()
      )
      .subscribe(([external, internal]) => {
        if (internal) this.groupUserId = internal.userId;
        if (external) this.portalUserId = external?.portalUserId;
      });

    this.postFormField = new FormGroup({
      title: new FormControl(''),
      description: new FormControl(''),
      image: new FormControl(''),
      groupsRestrictionEnabled: new FormControl(false),
      groups: new FormControl(''),
    });
  }

  submitPost() {
    const loadingsubj = new Subject<void>();
    this.loader.startLoading('Saving post...', () => loadingsubj);
    const savePost = (imgUrl?: string) => {
      const post = new PortalPost({
        name: this.postFormField.controls.title.value,
        pictureUrl: imgUrl,
        description: this.postFormField.controls.description.value,
        isDeleted: false,
        groupsRestrictionEnabled: this.groupsRestrictionEnabled,
        groups: this.groupsRestrictionEnabled
          ? this.selectedGroupIds.map(
              (id) => this.groups$.value.find((g) => g.id === id)!
            )
          : [],
        portalUserAuthorId: this.portalUserId,
        groupUserAuthorId: this.groupUserId,
      });
      return this.feedFac
        .createPost(post)
        .pipe(tap(() => this.dialogRef.close(this.postFormField.value)));
    };
    if (this.savedFile)
      this.uploadFile(this.savedFile)
        .pipe(switchMap(savePost))
        .subscribe(() => loadingsubj.next());
    else savePost().subscribe(() => loadingsubj.next());
  }

  saveFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      if (event.target?.result)
        this.postFormField.controls.image.setValue(event.target.result);
      console.log(event);
    };
    this.savedFile = file;
  }

  uploadFile(file: File) {
    return this.dataService.blobToBase64(file).pipe(
      switchMap((base64) => {
        return this.dataService.uploadImage(
          'IshtarPortalPostImages',
          file.name,
          file.type,
          base64
        );
      })
    );
  }

  resetFile() {
    this.savedFile = null;
    this.postFormField.controls.image.setValue('');
  }

  close() {
    this.dialogRef.close();
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
