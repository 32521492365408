import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {
  AbstractWebPartComponent,
  AbstractWebPartConfigComponent,
  MicrosoftAuthenticationService,
  VIEW,
} from 'processdelight-angular-components';
import { filter, first, race, Subject, takeUntil, zip } from 'rxjs';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import { UserLicenseInfo } from 'src/app/core/domain/models/user-license-info.model';
import { FeedFacade } from 'src/app/core/store/custom/feed/feed.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { PostPopupComponent } from '../post-popup/post-popup.component';
import { FeedConfigComponent } from './feed-config/feed-config.component';
import { ExternalUserInfo } from 'src/app/core/domain/models/external-user-info.model';
import { clear, Memoize } from 'typescript-memoize';
import { v4 } from 'uuid';
import { PortalPostLike } from 'src/app/core/domain/models/portal-post-like.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DialogModule } from '@angular/cdk/dialog';
import { CoreModule } from 'src/app/core/core.module';
import { FeedPostComponent } from './feed-post/feed-post.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export const CREATEPOST = 'Create Post';

@Component({
  selector: 'app-feed',
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatSnackBarModule,
    DatePipe,
    MatFormFieldModule,
    MatMenuModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    FeedPostComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
})
export class FeedComponent
  extends AbstractWebPartComponent
  implements OnInit, OnDestroy
{
  static override TYPE = 'CMSFeed';
  static override FEATURES = [VIEW, CREATEPOST];
  static override CONFIG_COMPONENT: new () => AbstractWebPartConfigComponent =
    FeedConfigComponent;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly feedFac: FeedFacade,
    private readonly userFacade: UserFacade,
    private readonly snackBar: MatSnackBar,
    private readonly msal: MicrosoftAuthenticationService
  ) {
    super();
  }

  loading = false;
  canCreatePosts = false;
  savingLike = false;
  searchTerm = '';
  orderBy: 'asc' | 'desc' = 'desc';

  portalUserId: string | undefined;
  groupUserId: string | undefined;

  feed: PortalPost[] = [];

  postTrackBy = (index: number, post: PortalPost) => post.id;

  ngOnInit(): void {
    this.canCreatePosts = this.canAccessFeature(CREATEPOST);

    zip([this.userFacade.externalUserInfo$, this.userFacade.userLicenseInfo$])
      .pipe(
        filter((e) => !!e),
        first()
      )
      .subscribe(([external, internal]) => {
        if (internal) this.groupUserId = internal.userId;
        if (external) this.portalUserId = external?.portalUserId;
      });

    this.feedFac.posts$.pipe(takeUntil(this.destroy$)).subscribe((posts) => {
      this.feed = posts ?? [];
      clear(['posts']);
    });

    this.searchPosts();
  }

  searchPosts() {
    this.loading = true;
    this.feedFac
      .getFeed$(
        this.portalUserId ?? this.groupUserId!,
        this.orderBy,
        this.searchTerm
      )
      .subscribe(() => {
        this.loading = false;
      });
    this.feed = [];
  }

  createNewPost() {
    this.matDialog.open(PostPopupComponent, {
      width: '100%',
      maxWidth: '750px',
      height: '100%',
      panelClass: 'p-4',
      data: {},
    });
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
