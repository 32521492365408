import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import {
  AbstractWebPartConfigComponent,
  WebPartConfig,
} from 'processdelight-angular-components';
import { Subject, takeUntil } from 'rxjs';
import * as uuid from 'uuid';
import { TranslationService } from 'src/app/core/services/translation.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { InterestGroupFacade } from 'src/app/core/store/interestGroup/interestGroup.facade';
import { InterestGroup } from 'src/app/core/domain/models/interestGroup';

@Component({
  selector: 'app-communities-of-interest-config',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './communities-of-interest-config.component.html',
  styleUrls: ['./communities-of-interest-config.component.scss'],
})
export class CommunitiesOfInterestConfigComponent
  extends AbstractWebPartConfigComponent
  implements OnInit, OnDestroy
{
  private translationService = inject(TranslationService);
  private interestGroupFacade = inject(InterestGroupFacade);
  private destroy$ = new Subject<void>();
  showAllGroups = true;
  oneGroup = false;
  coiConfig?: WebPartConfig;
  interestGroups: InterestGroup[] = [];

  form = new FormGroup({
    showAllGroups: new FormControl<boolean>(true),
    oneGroup: new FormControl<string | undefined>(undefined),
  });

  get selectValue() {
    return this.form.get('oneGroup')?.value;
  }

  getTranslation$(key: string) {
    return this.translationService.getTranslation$(key);
  }
  ngOnInit(): void {
    this.interestGroupFacade.interestGroups$
      .pipe(takeUntil(this.destroy$))
      .subscribe((groups) => {
        this.interestGroups = groups;
      });
    this.coiConfig = this.configurations?.find(
      (c) => c.name === 'communityGroupIds'
    );
    if (this.coiConfig) {
      const parsedConfiguration = this.getParsedConfiguration(
        this.coiConfig.configuration
      );
      this.form = new FormGroup({
        showAllGroups: new FormControl(
          parsedConfiguration.showAllGroups ?? true
        ),
        oneGroup: new FormControl(
          this.interestGroups.find((g) => g.id === parsedConfiguration.oneGroup)
            ?.id ?? undefined
        ),
      });
    } else {
      this.coiConfig = new WebPartConfig({
        id: uuid.v4(),
        name: 'communityGroupIds',
        configuration: '',
      });
    }

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const newConfig = new WebPartConfig({
        id: this.coiConfig?.id,
        name: this.coiConfig?.name,
        configuration: this.getStringifiedConfiguration(),
      });
      this.configurations = [newConfig];
      this.valueChanges.emit(this.configurations);
    });
  }

  change() {
    this.showAllGroups = !this.showAllGroups;
    this.form.patchValue({ showAllGroups: this.showAllGroups });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getParsedConfiguration(value: string) {
    return {
      showAllGroups: value ? JSON.parse(value).showAllGroups : true,
      oneGroup: value ? JSON.parse(value).oneGroup : '',
    };
  }

  getStringifiedConfiguration() {
    const showAllGroups = this.form.get('showAllGroups')?.value;
    const oneGroup = this.form.get('oneGroup')?.value;
    return JSON.stringify({ showAllGroups: showAllGroups, oneGroup: oneGroup });
  }
}
