import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  first,
  map,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { PortalService } from './portal.service';
import { UserFacade } from '../store/user/user.facade';

@Injectable({
  providedIn: 'root',
})
export class TranslationService implements OnDestroy {
  translations$ = new BehaviorSubject<{ [key: string]: string }>({});

  private sub?: Subscription;

  constructor(
    private ishtar365: PortalService,
    private userFacade: UserFacade
  ) {}

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = undefined;
    }
  }

  init() {
    if (!this.sub)
      this.sub = this.userFacade.userLicenseInfo$
        .pipe(
          switchMap((user) =>
            this.ishtar365.getTranslations(user?.language ?? 'en')
          ),
          tap((translations) => this.translations$.next(translations))
        )
        .subscribe();
  }

  getTranslations() {
    return this.translations$.pipe(first());
  }

  hasLabel(label: string) {
    return Object.keys(this.translations$.value).includes(label);
  }

  getTranslation(label: string, ...args: string[]) {
    let val = this.translations$.value[label];
    if (!val) return label;
    args.forEach((a, i) => {
      val = val.replace(`{${i}}`, a);
    });
    return val;
  }

  getTranslation$(label: string, ...args: string[]) {
    return this.translations$.pipe(
      map((t) => {
        let val = t[label];
        if (!val) return label;
        args.forEach((a, i) => {
          val = val.replace(`{${i}}`, a);
        });
        return val;
      })
    );
  }
}
