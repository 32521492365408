import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { post } from 'jquery';
import { PortalPostLike } from 'src/app/core/domain/models/portal-post-like.model';
import { FeedFacade } from 'src/app/core/store/custom/feed/feed.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { v4 } from 'uuid';
import { first, Subject, takeUntil } from 'rxjs';
import { ExternalUserInfo } from 'src/app/core/domain/models/external-user-info.model';
import { clear, Memoize } from 'typescript-memoize';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { PortalPostComment } from 'src/app/core/domain/models/portal-post-comment.model';
import { MatMenuModule } from '@angular/material/menu';
import { PortalUserVM } from 'src/app/core/domain/models/portal-user.model';
import { LikeListPopupComponent } from './like-list-popup/like-list-popup.component';
import { PortalUserPipe } from '../portal.user.pipe';
import { CdkMenuModule } from '@angular/cdk/menu';

@Component({
  selector: 'app-feed-post',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    MatFormFieldModule,
    MatMenuModule,
    PortalUserPipe,
    CdkMenuModule,
  ],
  templateUrl: './feed-post.component.html',
  styleUrls: ['./feed-post.component.scss'],
})
export class FeedPostComponent implements OnInit, OnDestroy {
  @Input() post!: PortalPost;
  @Input() groupUserId!: string | undefined;
  @Input() portalUserId!: string | undefined;

  savingLike = false;
  savingComment = false;

  showComment = false;

  newCommentText = new FormControl('');

  users: PortalUserVM[] = [];

  constructor(
    private readonly matDialog: MatDialog,
    private readonly feedFac: FeedFacade,
    private readonly userFacade: UserFacade,
    private readonly snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.feedFac.posts$.pipe(takeUntil(this.destroy$)).subscribe((posts) => {
      const thispost = posts?.find((p) => p.id === this.post.id);
      if (!thispost) return;
      this.post = thispost;
      clear(['post']);
    });
  }

  toggleLikePost() {
    this.savingLike = true;
    if (!this.isLikedByMe(this.post))
      this.feedFac
        .createLike(
          new PortalPostLike({
            id: v4(),
            postId: this.post.id,
            portalUserId: this.portalUserId,
            groupUserId: this.groupUserId,
          })
        )
        .subscribe(() => {
          this.savingLike = false;
        });
    else {
      this.removeLike(this.post);
    }
  }

  toggleLikeComment(comment: PortalPostComment) {
    this.savingLike = true;
    if (!this.isLikedByMe(comment))
      this.feedFac
        .createLike(
          new PortalPostLike({
            id: v4(),
            commentId: comment.id,
            portalUserId: this.portalUserId,
            groupUserId: this.groupUserId,
          })
        )
        .subscribe(() => {
          this.savingLike = false;
        });
    else {
      this.removeLike(comment);
    }
  }
  removeLike(post: PortalPost | PortalPostComment) {
    const mylike = post.likes.find(
      (l) =>
        l.portalUserId === this.portalUserId ||
        l.groupUserId === this.groupUserId
    );
    if (!mylike) {
      console.error('Could not find like to remove');
      return;
    }
    this.feedFac.removeLike(mylike).subscribe(() => {
      this.savingLike = false;
    });
  }

  createComment() {
    if (!this.newCommentText.value) return;
    this.savingComment = true;
    this.feedFac
      .createComment(
        new PortalPostComment({
          postId: this.post.id,
          portalUserId: this.portalUserId,
          groupUserId: this.groupUserId,
          message: this.newCommentText.value,
        })
      )
      .subscribe((d) => {
        this.newCommentText.setValue('');
        this.savingComment = false;
      });
  }

  openLikeList(post: PortalPost | PortalPostComment) {
    this.matDialog.open(LikeListPopupComponent, {
      data: { likes: post.likes },
      maxHeight: '70%',
      width: '30%',
    });
  }

  @Memoize({ tags: ['post'] })
  isLikedByMe(post: PortalPost | PortalPostComment) {
    return post.likes.some(
      (like) =>
        like.portalUserId === this.portalUserId ||
        like.groupUserId === this.groupUserId
    );
  }

  deleteComment(comment: PortalPostComment) {
    this.savingComment = true;
    this.feedFac.removeComment(comment).subscribe(() => {
      this.savingComment = false;
    });
  }

  @Memoize({ tags: ['post'], hashFunction: (i) => i })
  getLastLikeComment(post: PortalPost | PortalPostComment) {
    const getUser = (like: PortalPostLike) =>
      like.portalUser?.displayName ?? like.groupUser?.displayName;
    if (post.likes.length === 0) return 'No Likes';
    if (post.likes.length === 1) return getUser(post.likes[0]) + ' liked this';
    const lastLike = post.likes[post.likes.length - 1];
    return getUser(lastLike) + ' and ' + (post.likes.length - 1) + ' others';
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
