import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import { DataService } from 'src/app/webparts/dms-component/services/data.service';
import {
  createComment,
  createCommentResolved,
  createPost,
  createPostResolved,
  deleteComment,
  deleteCommentResolved,
  getPosts,
  getPostsResolved,
  likePost,
  likePostResolved,
  removeLike,
  removeLikeResolved,
} from './feed.actions';
import { PortalService } from 'src/app/core/services/portal.service';

@Injectable({ providedIn: 'root' })
export class FeedEffects {
  constructor(private actions$: Actions, private api: DataService) {}

  getPosts = createEffect(() =>
    this.actions$.pipe(
      ofType(getPosts),
      switchMap(({ callback, error, portalUserId, orderByDir, filter }) =>
        this.api.getPosts(portalUserId, orderByDir, filter).pipe(
          tap((posts) => (callback ? callback(posts) : undefined)),
          switchMap((posts) => of(getPostsResolved({ feed: posts }))),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );

  createPost = createEffect(() =>
    this.actions$.pipe(
      ofType(createPost),
      switchMap(({ callback, error, post }) =>
        this.api.createPost(post).pipe(
          tap((post) => (callback ? callback(post) : undefined)),
          switchMap((post) => of(createPostResolved({ post }))),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );

  likePost = createEffect(() =>
    this.actions$.pipe(
      ofType(likePost),
      switchMap(({ callback, error, like }) =>
        this.api.createLike(like).pipe(
          tap((like) => (callback ? callback(like) : undefined)),
          switchMap((like) => of(likePostResolved({ like }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeLike = createEffect(() =>
    this.actions$.pipe(
      ofType(removeLike),
      switchMap(({ callback, error, like }) =>
        this.api.removeLike(like.id).pipe(
          tap((id) => (callback ? callback(id) : undefined)),
          switchMap((id) =>
            of(
              removeLikeResolved({
                likeId: id,
                postId: like.postId,
                commentId: like.commentId,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  createComment = createEffect(() =>
    this.actions$.pipe(
      ofType(createComment),
      switchMap(({ callback, error, comment }) =>
        this.api.createComment(comment).pipe(
          tap((comment) => (callback ? callback(comment) : undefined)),
          switchMap((comment) => of(createCommentResolved({ comment }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeComment = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteComment),
      switchMap(({ callback, error, comment }) =>
        this.api.removeComment(comment.id).pipe(
          tap((commentId) => (callback ? callback(commentId) : undefined)),
          switchMap((commentId) =>
            of(deleteCommentResolved({ commentId, postId: comment.postId }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
