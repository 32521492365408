<div class="postcard">
  <div class="postcontent">
    <!-- Post Content -->
    <h3>{{ post.name }}</h3>

    <div *ngIf="post.pictureUrl" class="imgcontainer">
      <img class="postimg" [src]="post.pictureUrl" alt="Post Image" />
    </div>

    <div class="flex-grow-1 pt-2" [innerHTML]="post.description"></div>
  </div>
  <div class="postInfo">
    <!-- Post Actions & Info -->

    <div class="d-flex flex-row gap-2">
      <div class="contentfooter ps-3">
        <p>{{ post.createdOn?.toJSDate() | date : "dd/MM/yyyy HH:mm" }}</p>
        <p class="author">
          by
          {{ post | portalUser }}
        </p>
      </div>
    </div>
    <div class="postactions">
      <div class="likediv">
        <button
          mat-button
          class="likebtn"
          [disabled]="savingLike"
          (click)="toggleLikePost()"
        >
          <mat-icon class="chaticon">
            {{ isLikedByMe(post) ? "favorite" : "favorite_outline" }}
          </mat-icon>
        </button>
        <button
          mat-button
          (click)="post.likes?.length ? openLikeList(post) : undefined"
        >
          <span class="btnpsan"> {{ getLastLikeComment(post) }} </span>
        </button>
      </div>
      <button disabled mat-button class="counterbtn">
        <mat-icon class="chaticon">chat</mat-icon>
        <span class="btnpsan">{{ post.comments?.length }} Comments </span>
      </button>
    </div>
  </div>

  <!-- Post Comment Section -->

  <div class="writecommentcard">
    <input
      class="newcomment"
      placeholder="Write a comment"
      [disabled]="savingComment"
      [formControl]="newCommentText"
      (keydown.enter)="createComment()"
    />
  </div>

  <div class="commentscard">
    <div *ngFor="let comment of post.comments" class="comment">
      <div class="d-flex flex-column">
        <div class="d-flex flex-row justify-content-between">
          <p>{{ comment.message }}</p>
        </div>
        <div class="contentfooter gap-3">
          <p class="m-0">
            {{ comment.createdOn?.toJSDate() | date : "dd/MM/yyyy" }}
          </p>
          <p class="author">
            by
            {{ comment | portalUser }}
          </p>
        </div>
      </div>

      <div class="commentactions">
        <div class="commentlikediv">
          <button
            mat-button
            class="likebtn"
            [disabled]="savingLike"
            (click)="toggleLikeComment(comment)"
          >
            <mat-icon class="chaticon">{{
              isLikedByMe(comment) ? "favorite" : "favorite_outline"
            }}</mat-icon>
          </button>
          <button
            mat-button
            class="likebtn"
            (click)="comment.likes?.length ? openLikeList(comment) : undefined"
          >
            <span>
              {{ comment.likes?.length }}
            </span>
          </button>
        </div>
        <button mat-button [matMenuTriggerFor]="commentMenu" class="likebtn">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #commentMenu="matMenu">
          <button
            mat-menu-item
            [disabled]="
              (comment.portalUserId !== portalUserId &&
                comment.groupUserId !== groupUserId) ||
              savingComment
            "
            (click)="deleteComment(comment)"
          >
            delete <mat-icon>remove</mat-icon>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>

<mat-card class="commentscard"> </mat-card>
