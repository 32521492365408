import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { BaseFacade } from '../../base.facade';
import {
  createComment,
  createPost,
  deleteComment,
  getPosts,
  likePost,
  removeLike,
  resetSlice,
} from './feed.actions';
import { feedSelectors } from './feed.selectors';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import { PortalPostLike } from 'src/app/core/domain/models/portal-post-like.model';
import { first, of } from 'rxjs';
import { PortalPostComment } from 'src/app/core/domain/models/portal-post-comment.model';

@Injectable({
  providedIn: 'root',
})
export class FeedFacade extends BaseFacade {
  posts$ = this._store.pipe(select(feedSelectors.getFeed));

  getFeed$(portalUserId: string, orderByDir: 'asc' | 'desc', filter?: string) {
    // let posts: PortalPost[] | undefined;
    // this.posts$.pipe(first()).subscribe((p) => (posts = p));
    // if (posts) return of(posts);
    return this.dispatchAction$(getPosts, { portalUserId, orderByDir, filter });
  }

  createPost(post: PortalPost) {
    return this.dispatchAction$(createPost, { post });
  }

  createLike(like: PortalPostLike) {
    return this.dispatchAction$(likePost, { like });
  }

  removeLike(like: PortalPostLike) {
    return this.dispatchAction$(removeLike, { like });
  }

  createComment(comment: PortalPostComment) {
    return this.dispatchAction$(createComment, { comment });
  }

  removeComment(comment: PortalPostComment) {
    return this.dispatchAction$(deleteComment, { comment });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
