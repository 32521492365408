import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DateTime } from 'luxon';
import { catchError, map, mergeMap, of, switchMap, takeUntil, tap } from 'rxjs';
import { DataService } from 'src/app/webparts/dms-component/services/data.service';
import { TranslationService } from '../../services/translation.service';
import {
  getLibraries,
  getLibrariesResolved,
  getLibrary,
  getLibraryItems,
  getLibraryItemsResolved,
  getLibraryResolved,
  getSharedItems,
} from './library.actions';

@Injectable({ providedIn: 'root' })
export class LibraryEffects {
  constructor(
    private actions$: Actions,
    private api: DataService,
    private translations: TranslationService
  ) {}

  getTranslation(label: string): string {
    return this.translations.getTranslation(label);
  }

  getLibraries = createEffect(() =>
    this.actions$.pipe(
      ofType(getLibraries),
      switchMap(({ callback, error }) =>
        this.api.getLibraries().pipe(
          tap((result) => (callback ? callback(result) : undefined)),
          switchMap((libraries) => of(getLibrariesResolved({ libraries }))),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );

  getLibrary = createEffect(() =>
    this.actions$.pipe(
      ofType(getLibrary),
      switchMap(({ id, callback, error }) =>
        this.api.getLibrary(id).pipe(
          tap((result) => (callback ? callback(result) : undefined)),
          switchMap((library) => of(getLibraryResolved({ library }))),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );

  getSharedItems = createEffect(() =>
    this.actions$.pipe(
      ofType(getSharedItems),
      mergeMap(
        ({
          orderBy,
          orderByDirection,
          filters,
          pageSize,
          page,
          callback,
          error,
        }) =>
          this.api
            .getSharedItems(orderBy, orderByDirection, filters, pageSize, page)
            .pipe(
              tap((result) => (callback ? callback(result) : undefined)),
              switchMap((result) => of(getLibraryItemsResolved(result))),
              catchError((e) => {
                if (error) error(e);
                return [];
              })
            )
      )
    )
  );
  getLibraryItems = createEffect(() =>
    this.actions$.pipe(
      ofType(getLibraryItems),
      mergeMap(
        ({
          orderBy,
          orderByDirection,
          filters,
          pageSize,
          page,
          filterActive,
          libraryId,
          filteredInterestGroupIds,
          reset$,
          callback,
          error,
        }) =>
          this.api
            .getLibraryItems(
              orderBy,
              orderByDirection,
              filters,
              pageSize,
              page,
              filterActive,
              libraryId,
              filteredInterestGroupIds
            )
            .pipe(
              reset$ ? takeUntil(reset$()) : tap(),
              map((response) => ({
                ...response,
                result: response.result.map((item) => ({
                  ...item,
                  metadata: item.metadata.map((metadataItem) => ({
                    ...metadataItem,
                    dateTimeValue: metadataItem.dateTimeValue
                      ? DateTime.fromISO(metadataItem.dateTimeValue).toFormat(
                          'dd/MM/yyyy HH:mm'
                        )
                      : metadataItem.dateTimeValue,
                  })),
                })),
              })),
              tap((result) => {
                callback ? callback(result) : undefined;
              }),
              switchMap((result) => of(getLibraryItemsResolved(result))),
              catchError((e) => {
                if (error) error(e);
                return [];
              })
            )
      )
    )
  );
}
