<div class="dialogWrapper">
  <div
    class="titleContainer d-flex flex-column justify-content-center align-items-center"
  >
    <div class="title">
      <h5
        style="margin: 0px"
        *ngIf="
          getTranslation$('accountSettings') | async as accountSettingsLabel
        "
      >
        {{ accountSettingsLabel }}
      </h5>
    </div>
    <processdelight-action-bar
      [navColor]="'#124464'"
      [navContrast]="'#FFF'"
      [iconActions]="iconActions"
      [searchEnabled]="false"
      [sideBarButtonEnabled]="false"
    ></processdelight-action-bar>
  </div>
  <mat-tab-group class="h-100 tabgroups">
    <mat-tab label="Profile">
      <ng-template
        mat-tab-label
        *ngIf="getTranslation$('profile') | async as profileLabel"
        >{{ profileLabel }}
      </ng-template>
      <app-profile class="tabcontent"></app-profile>
    </mat-tab>
    <!-- <mat-tab label="Notifications">
      <ng-template mat-tab-label>Notifications </ng-template>
        
        <app-estimation
          class="tabcontent"
          *ngIf="selectedProject"
          [selectedProject]="selectedProject"
          [tabsDisabled]="tabsDisabled"
          [editTemplate]="data.editTemplate"
          (unsavedChanges)="changesFromEstimation($event)"
        ></app-estimation>
    </mat-tab> -->
    <mat-tab label="Groups" bodyClass="overflow-hidden">
      <ng-template
        *ngIf="getTranslation$('groups') | async as groupLabel"
        mat-tab-label
        >{{ groupLabel }}</ng-template
      >
      <app-group-settings class="tabcontent"></app-group-settings>
    </mat-tab>
  </mat-tab-group>
</div>
