import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { InterestGroup } from 'src/app/core/domain/models/interestGroup';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslationService } from 'src/app/core/services/translation.service';

interface Groups {
  id: string;
  title: string;
  color: string;
  description?: string;
  icon?: string;
  isSelected: boolean;
  children?: Groups[];
}

@Component({
  selector: 'app-coi-filter-popup',
  templateUrl: './coi-filter-popup.component.html',
  styleUrls: ['./coi-filter-popup.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatTreeModule,
    MatCardModule,
    MatCheckboxModule,
    CommonModule,
  ],
})
export class CoiFilterPopupComponent implements OnInit, OnDestroy {
  treeControl = new NestedTreeControl<Groups>((node) => node.children ?? []);
  dataSource = new MatTreeNestedDataSource<Groups>();

  hasChild = (_: number, node: Groups) =>
    !!node.children && node.children.length > 0;

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      interestGroups: InterestGroup[];
      dataSource: Groups[];
    },
    private readonly sanitizer: DomSanitizer,
    private readonly translations: TranslationService,
    public filterDialogRef: MatDialogRef<CoiFilterPopupComponent>
  ) {}

  ngOnInit(): void {
    this.dataSource.data = this.data.dataSource;
  }

  buildTree(interestGroups: InterestGroup[]): Groups[] {
    if (!interestGroups) {
      return [];
    }
    return interestGroups.map((g) => ({
      id: g.id,
      title: g.title,
      color: g.color,
      description: g.description,
      icon: g.icon,
      isSelected: g.isSelected,
      children: this.getConvertedNodes(g),
    }));
  }

  getConvertedNodes(interestGroup: InterestGroup): Groups[] {
    if (interestGroup.subGroups && interestGroup.subGroups.length === 0) {
      return [];
    }
    const r = interestGroup.subGroups!.map((group) => {
      const subGroup = this.data.interestGroups?.find(
        (g) => g.id === group.subGroupId
      );
      return {
        id: subGroup!.id,
        title: subGroup!.title,
        color: subGroup!.color,
        description: subGroup!.description,
        icon: subGroup!.icon,
        isSelected: subGroup!.isSelected,
        children: this.getConvertedNodes(subGroup!),
      };
    });
    return r;
  }

  checkBoxChange(event: MatCheckboxChange, node: Groups) {
    let groupsToCheck = this.data.interestGroups;
    let allGroupsToJoin = [node.id];
    if (event.checked) {
      let newSubGroups = allGroupsToJoin;
      do {
        newSubGroups = groupsToCheck
          .filter(
            (g) =>
              !allGroupsToJoin.includes(g.id) &&
              g.subGroups?.some((s) => newSubGroups.includes(s.subGroupId))
          )
          .map((g) => g.id);
        allGroupsToJoin = allGroupsToJoin.concat(newSubGroups);
      } while (newSubGroups.length != 0);
    }
    groupsToCheck = groupsToCheck.map((g) => {
      if (allGroupsToJoin.includes(g.id)) {
        g.isSelected = event.checked;
      }
      return g;
    });
    this.dataSource.data = this.buildTree(groupsToCheck);
  }

  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  saveFilters() {
    this.filterDialogRef.close(this.dataSource.data);
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
